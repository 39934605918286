import React ,{Component} from 'react';
import Layout from './Layout';

class Dashboard extends Component{
	render()
	{
		return (
			<>
				<Layout/>
			 </>
			);
	}
}
export default Dashboard;